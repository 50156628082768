<template>
  <Navigation/>
  <Showcase/>
  <!-- <About/> -->
  <Footer/>
</template>

<script>
import Navigation from './components/Navigation.vue'
import Showcase from './components/Showcase.vue'
import Footer from './components/Footer.vue'
// import About from './components/About.vue'

export default {
    components: {
        Navigation,
        Showcase,
        Footer,
        // About,
    }
}
</script>

<style lang="scss">
@import './scss/styles.scss';


.navbar > .container {
        flex-wrap: nowrap;
    }
</style>
