<template>
  <footer class="bg-dark text-center text-lg-start">
  <!-- <footer class="bg-dark text-center text-lg-start mt-5"> -->
    <div class="container d-flex flex-column flex-md-row">
      <!-- Copyright -->
      <a class="navbar-brand pt-2 pb-2" href="#">
        <img src="@/assets/ens-auto-logo-2.png" class="logo" alt="ENSAUTO">
      </a>
      <!-- Copyright -->

      <!--  Navbar -->
      <ul class="navbar-nav flex-row ms-auto mb-2 mb-lg-0 flex-wrap justify-content-center align-items-center">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Ana Sayfa</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://ensautoantalya.sahibinden.com/">Araçlarımız</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://www.google.com/maps/place/Ens+Oto+%26+Emlak/@36.9280097,30.6663409,17z/data=!3m1!4b1!4m5!3m4!1s0x14c38fb308309b69:0x412688d7e6152d42!8m2!3d36.9280687!4d30.668578">Konum</a>
        </li>
        <li class="nav-item">
          <!-- <a class="nav-link" href="#about">Hakkında</a> -->
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#contact">İletişim</a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>

.nav-item {
  white-space: nowrap;
}
</style>