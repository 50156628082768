<template>
  <div class="_wrap">
    <div class="container ">
      
      <div class="row no-gutters _showcase justify-content-xl-between align-items-md-center">
        <div class="col-lg-6 col-md-6 d-flex align-items-md-end">
          <div class="">
            <h1 class="mb-4 _showcase-header">
              Antalya'da
              <span>Güvenilir ikinci el</span>
              <span> araç alım satım.</span>
            </h1>
          </div>
        </div>

        <div class="info-card col-lg-4 col-md-6 mt-0 mt-md-5 d-flex" id="contact">
          <div class="card">
            <div class="card-body">
              
              <h5 class="card-title text-title">İletişim Bilgileri</h5>
              
              <div class="row">
                <h6 class="card-subtitle text-subtitle">Adres</h6>
                <div class="card-text d-flex align-items-center">
                  <span class="material-icons-outlined icon-card">room</span>
                  <span class="link-group-text">Kanal, Ahmet Vefik Paşa Cd. 68/A, 07080 Kepez/Antalya</span> 
                </div>
              </div>
              
              <div class="row">
                <h6 class="card-subtitle text text-subtitle">Telefon</h6>
                <div class="card-text d-flex align-items-center">
                  <span class="material-icons-outlined icon-card"
                    >local_phone</span>
                    <span class="link-group-text">+90 542 253 08 46</span>
                </div>
              </div>
              
              <div class="row">
                <h6 class="card-subtitle text text-subtitle">E-Mail</h6>
                <div class="card-text d-flex align-items-center">
                  <span class="material-icons-outlined icon-card">email</span>
                  <span class="link-group-text">enisyildirim07@gmail.com</span>
                </div>
              </div>
              
              <div class="row">
                <h6 class="card-subtitle text text-subtitle">Konum</h6>
                <div class="card-text d-flex align-items-center">
                  <a href="https://www.google.com/maps/place/Ens+Oto+%26+Emlak/@36.9280097,30.6663409,17z/data=!3m1!4b1!4m5!3m4!1s0x14c38fb308309b69:0x412688d7e6152d42!8m2!3d36.9280687!4d30.668578" class="link-group d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 150 150"
                      class="icon-svg"
                    >
                      <path
                        fill="#1a73e8"
                        d="M89.77,10.4c-4.4-1.39-9.08-2.15-13.94-2.15c-14.18,0-26.87,6.41-35.33,16.48l21.8,18.34L89.77,10.4z"
                      />
                      <path
                        fill="#ea4335"
                        d="M40.49,24.73c-6.74,8.02-10.81,18.37-10.81,29.66c0,8.68,1.73,15.71,4.57,22.01l28.04-33.33L40.49,24.73z"
                      />
                      <path
                        fill="#4285f4"
                        d="M75.83,36.75c9.75,0,17.65,7.9,17.65,17.65c0,4.34-1.57,8.32-4.17,11.39c0,0,13.94-16.58,27.47-32.66   c-5.59-10.75-15.28-19.02-27-22.73L62.29,43.07C65.53,39.2,70.39,36.75,75.83,36.75"
                      />
                      <path
                        fill="#fbbc04"
                        d="M75.83,72.04c-9.75,0-17.65-7.9-17.65-17.65c0-4.31,1.55-8.26,4.11-11.33L34.25,76.4   c4.79,10.63,12.76,19.16,20.97,29.91L89.3,65.79C86.07,69.61,81.23,72.04,75.83,72.04"
                      />
                      <path
                        fill="#34a853"
                        d="M88.63,117.37c15.39-24.07,33.34-35,33.34-62.98c0-7.67-1.88-14.9-5.19-21.26l-61.55,73.18   c2.61,3.42,5.24,7.06,7.81,11.07c9.36,14.46,6.76,23.13,12.8,23.13C81.86,140.51,79.27,131.83,88.63,117.37"
                      />
                    </svg>
                    <a href="https://www.google.com/maps/place/Ens+Oto+%26+Emlak/@36.9280097,30.6663409,17z/data=!3m1!4b1!4m5!3m4!1s0x14c38fb308309b69:0x412688d7e6152d42!8m2!3d36.9280687!4d30.668578" class="link-group link-group-text">Konumu Google Maps'de açmak için tıklayınız.</a>
                  </a>
                </div>
              </div>
              
              <div class="row">
                <div class="d-flex align-items-center justify-content-between mb-2">
                  <a href="https://ensautoantalya.sahibinden.com/" class="link-sahibinden">
                    <img class="icon-wide" src="@/assets/sahibinden.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
._wrap {
  width: 100%;
  height: 850px;
  background-image: url("../assets/car.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

._showcase {
  height: 850px;
  &-header {
      font-size: 60px;
      color: #fff;
      line-height: 1.1;
      font-weight: 200;
  }
}

.info-card {
  min-width: 356px;
}

.card {
    
    &-body {
      padding: 30px;
    }
}

.row + .row {
  margin-top: 25px;
}

.text {
    font-weight: 600;
    color: #000;
  
  &-title {
    font-size: 22px;
    margin-bottom: 26px;
  }

  &-subtitle {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
}

.link {
    
    &-sahibinden {
        width: 100%;
        background-color: #fff100;
        display: flex;
        justify-content: center;
        margin-top: 8px;
    }

    &-group {
        color: #212529;
        text-decoration: none;

        &-text {
            margin-left: 12px;
            color: #0006;
        }
    }
}

.icon {
  &-card {
    margin-right: 12px;
    font-size: 30px;
  }

  &-link {
    height: 55px;
    margin-right: 20px;
  }

  &-wide {
    height: 55px;
  }

  &-svg {
    width: 45px;
    height: 30px;
    margin-right: 12px;
  }
}
</style>