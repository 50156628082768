 <template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark _navbar-light">
  <!-- <nav class="navbar navbar-expand-lg navbar-dark bg-dark _navbar-light"> -->
  <div class="container">
    <!-- Navbar Brand -->
    <a class="navbar-brand" href="#">
      <img src="@/assets/ens-auto-logo-2.png" class="logo" alt="ENSAUTO">
    </a>

    <!-- Navbar Toggler -->
    <button style="display: none" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- Navbar Collapse -->
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <a class="nav-link" href="#">Ana Sayfa</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" href="https://ensautoantalya.sahibinden.com/">Araçlarımız</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://www.google.com/maps/place/Ens+Oto+%26+Emlak/@36.9280097,30.6663409,17z/data=!3m1!4b1!4m5!3m4!1s0x14c38fb308309b69:0x412688d7e6152d42!8m2!3d36.9280687!4d30.668578">Konum</a>
        </li>
        <li class="nav-item">
          <!-- <a class="nav-link" href="#about">Hakkında</a> -->
        </li>
        <li class="nav-item">
          <a class="nav-link nav-last-item" href="#contact">İletişim</a>
        </li>
      </ul> 
    </div>
  </div>
</nav>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

.nav-last-item {
  margin-right: -20px;
}

</style>